import {MdExitToApp, MdPerson} from 'react-icons/md';
import {LOGIN} from '../../../config/constants/routes';
import {getMailAuthLinkWithParamsHooks} from '../../services/getMailAuthLinkWithParamsHooks';

export const HeaderAuthActionMenu: React.FC = () => {
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  const items = [
    {
      id: 'login',
      icon: <MdExitToApp size={20} />,
      href: LOGIN,
      label: 'ログイン',
    },
    {
      id: 'registration',
      icon: <MdPerson size={20} />,
      href: mailAuthLink,
      label: '無料会員登録',
    },
  ];

  return (
    <ul className="bg-white text-black-1 text-sm divide-line flex flex-col divide-y py-4 px-12 pc:w-[197px] w-full">
      {items.map((item) => {
        const {id, icon, href, label} = item;

        return (
          <li key={id || item.href}>
            <a href={href} className="flex items-center p-8">
              <div className="mr-8">{icon}</div>
              <div>{label}</div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
