import {destroyCookie} from 'nookies';
import {useCallback, useState} from 'react';
import {
  type UsersMeResponse,
  useUsersMe,
} from '../repositories/usersMeRepository';
import {cookieApiTokenKey, cookieSessionKey} from '../../server/token';
import {checkAccessTokenExistence} from '../../shared/services/checkAccessTokenExistence';
import {extractAccessTokenFromCookie} from '../../shared/services/extractAccessTokenFromCookie';
import {HOME} from '../../config/constants/routes';

export type UserType = UsersMeResponse;

export const useAuth = () => {
  const accessToken = extractAccessTokenFromCookie();
  const {isValid, providedAccessToken} = checkAccessTokenExistence(accessToken);

  const [user, setUser] = useState<UserType | undefined>(undefined);
  const [isIdle, setIsIdle] = useState(isValid);

  useUsersMe({
    accessToken: providedAccessToken,
    config: {
      enabled: isValid,
      onSuccess(data) {
        if (data.ok) {
          setUser(data.value);
          setIsIdle(false);
        }
      },
      onError() {
        setIsIdle(false);
      },
    },
  });

  const signOut = useCallback(() => {
    destroyCookie(null, cookieApiTokenKey);
    destroyCookie(null, cookieSessionKey);

    window.location.href = HOME;
  }, []);

  return {
    user,
    accessToken: providedAccessToken,
    signOut,
    isIdle,
    isSignedIn: !isIdle && Boolean(user),
  };
};
