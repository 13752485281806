import {Text} from '@mantine/core';
import Image from 'next/image';
import {useRouter} from 'next/router';
import type {FC} from 'react';
import {MdMenu, MdClear, MdAccountCircle, MdOpenInNew} from 'react-icons/md';
import {COLOR_BLACK_1} from '../../../../config/constants/colorCodes';
import {ENGINEER_LOGO_IMAGE_PATH_DEFAULT} from '../../../../config/constants/img';
import {
  ENGINEER,
  ENGINEER_COLUMN,
  ENGINEER_COMPANY,
  ENGINEER_RECRUITING_INFO,
  REPORT_ANALYTICS_SEARCH,
} from '../../../../config/constants/routes';
import {type UserType} from '../../../../core/usecases/useAuth';
import {useAuthAndCounts} from '../../../../core/usecases/useAuthAndCounts';
import {useBooleanState} from '../../../services/useBooleanState';
import {HeaderMenu} from '../HeaderMenu';

type Props = {
  scoutsCount?: number;
  isSignedIn?: boolean;
  interestsCount?: number;
  className?: string;
};

const menuItems = [
  {
    name: '募集を探す',
    url: ENGINEER_RECRUITING_INFO,
  },
  {
    name: '企業を探す',
    url: ENGINEER_COMPANY,
  },
  {
    name: 'ES・体験記',
    url: '/engineer/company/reports',
  },
  {
    name: 'コラム',
    url: ENGINEER_COLUMN,
  },
  {
    name: 'イベント',
    url: `${ENGINEER}#events`,
  },
  {
    name: 'AI選考対策',
    url: REPORT_ANALYTICS_SEARCH,
    isNew: true,
  },
];

const topPageMenuItems = [
  {
    name: '募集を探す',
    url: '#recruiting_info',
  },
  {
    name: '企業を探す',
    url: '#companies',
  },
  {
    name: 'ES・体験記',
    url: '#company_reports',
  },
  {
    name: 'コラム',
    url: '#columns',
  },
  {
    name: 'イベント',
    url: '#events',
  },
  {
    name: 'AI選考対策',
    url: REPORT_ANALYTICS_SEARCH,
    isNew: true,
  },
];

const hamburgerList: Array<{
  name: string;
  url: string;
}> = [
  {
    name: '外資就活トップ',
    url: '/',
  },
  {
    name: 'コミュニティ',
    url: '/questions',
  },
  {
    name: '相談室',
    url: '/box/answers',
  },
  {
    name: '各種設定・その他',
    url: '/mypage/profile',
  },
];

export const EngineerPcHeader: FC<Props> = ({className}) => {
  const {user, isIdle, scoutsCount, interestsCount, isSignedIn} =
    useAuthAndCounts();

  return (
    <div className={className}>
      {user && !isIdle ? (
        <AuthedHeader
          user={user}
          isSignedIn={isSignedIn}
          scoutsCount={scoutsCount}
          interestsCount={interestsCount}
        />
      ) : (
        <GuestHeader />
      )}
    </div>
  );
};

const AuthedHeader: FC<{user: UserType} & Props> = ({
  user,
  isSignedIn,
  scoutsCount = 0,
  interestsCount = 0,
}) => {
  const [shownMenu, showMenu, hideMenu] = useBooleanState(false);
  const [hamburgerOpened, , hamburgerClose, hamburgerToggle] =
    useBooleanState(false);
  const hasNotification = scoutsCount > 0 || interestsCount > 0;
  const router = useRouter();
  const menu = router.pathname === ENGINEER ? topPageMenuItems : menuItems; // TOPページはアンカーリンク、それ以外は一覧ページに遷移

  return (
    <div>
      <div className="flex h-64 items-center justify-between px-48 text-base fixed w-full top-0 z-50 bg-white">
        <div className="flex items-center gap-20">
          <button
            id="drawer-button"
            aria-label="Drawer Button"
            onClick={hamburgerToggle}
            className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out "
          >
            {hamburgerOpened ? (
              <MdClear size={24} fill={COLOR_BLACK_1} />
            ) : (
              <div className="relative">
                <MdMenu fill={COLOR_BLACK_1} size={24} />
              </div>
            )}
          </button>
          <a href={ENGINEER}>
            <Image
              src={ENGINEER_LOGO_IMAGE_PATH_DEFAULT}
              alt="logo"
              width={194}
              height={18}
            />
          </a>
        </div>
        <div className="flex items-center gap-16">
          <div className="flex gap-16">
            {menu.map((item) => (
              <a
                key={item.name}
                href={item.url}
                className="font-bold text-black-1 flex items-center"
              >
                <div
                  className={`bg-accent-1 text-white font-bold rounded-12 text-center text-[8px] w-32 h-12 mr-4 ${item.isNew ? '' : 'hidden'}`}
                >
                  NEW
                </div>
                {item.name}
              </a>
            ))}
          </div>
          <div
            className="relative flex items-center gap-8 text-black-1"
            onMouseEnter={() => {
              showMenu();
            }}
            onMouseLeave={() => {
              hideMenu();
            }}
          >
            <div className="relative">
              <MdAccountCircle size={24} />
              {hasNotification && (
                <div className="absolute right-0 top-0 flex h-8 w-8 rounded-full bg-notification" />
              )}
            </div>
            <div className="text-sm font-bold">{user.familyName}さん</div>
            {shownMenu && (
              <div className="absolute right-0 top-[22px] z-50 pt-[26px] ">
                <div className="overflow-hidden rounded-12 bg-background-1 shadow-modal">
                  <HeaderMenu
                    onItemClick={hideMenu}
                    scoutsCount={scoutsCount}
                    interestsCount={interestsCount}
                    showMyPage={isSignedIn}
                    className="w-[184px] text-black-1"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {hamburgerOpened && (
          <div className="absolute top-48 left-48 bg-white shadow-modal p-12 flex flex-col z-10 rounded-12">
            {hamburgerList.map((item) => (
              <button
                onClick={() => {
                  window.open(item.url);
                }}
                className="w-[160px] p-8 border-b-[1px] text-base text-left justify-between flex items-center"
              >
                <Text>{item.name}</Text>
                <MdOpenInNew />
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="w-full border-[0.5px]" />
      <div className="w-full py-12 pl-48 h-[45px] flex text-black-1 font-bold text-base mt-[50px]">
        エンジニアとして理想のファーストキャリアを目指そう
      </div>
    </div>
  );
};

const GuestHeader: FC = () => {
  return (
    <div className="flex h-64 items-center justify-between bg-white px-64 text-base shadow-modal fixed w-full top-0 z-50">
      <div className="flex items-center gap-20">
        <a href={ENGINEER}>
          <Image
            src={ENGINEER_LOGO_IMAGE_PATH_DEFAULT}
            alt="logo"
            width={194}
            height={18}
          />
        </a>
      </div>
      <div className="flex h-64 items-center justify-between bg-white">
        <div className="flex items-center gap-16">
          <a href={'/login'} className="font-bold text-primary-1">
            ログイン・新規登録
          </a>
        </div>
      </div>
    </div>
  );
};
