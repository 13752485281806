import {ClientProvider} from '@packages/client';
import {QueryClientProvider} from '@tanstack/react-query';
// Import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import {BadRequestError, ForbiddenError} from '@packages/utils';
import {type NextPage} from 'next';
import {type AppProps} from 'next/app';
import Head from 'next/head';
import Image from 'next/image';
import {type ReactElement, type ReactNode} from 'react';
import {RecoilRoot} from 'recoil';
import {FAVICON_PATH, OGP_IMAGE_PATH_DEFAULT} from '../config/constants/img';
import {bugsnag} from '../lib/bugsnag';
import {queryClient} from '../lib/react-query';
import {initMocks} from '../mocks';
import {ClientSideErrorPage} from '../shared/components/Error/ClientSideErrorPage';
import {RegistrationNavigationFloating} from '../shared/components/RegistrationNavigationFloating';
import {ScoutNotificationModal} from '../shared/components/ScoutNotificationModal';
import '../styles/globals.css';

// MEMO: APIモックを有効化する
// 有効にしない場合は、process.env.NEXT_PUBLIC_API_MOCKINGを空にする
// process.env.NEXT_PUBLIC_API_MOCKING === ""
if (
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
) {
  void initMocks();
}

bugsnag();

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({Component, pageProps}: AppPropsWithLayout): JSX.Element => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const {error} = pageProps;

  return (
    <>
      <RecoilRoot>
        <ClientProvider>
          <QueryClientProvider client={queryClient}>
            <Head>
              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
              />
              <meta name="format-detection" content="telephone=no" />
              <meta name="mobile-web-app-capable" content="yes" />

              <title key="title">外資就活ドットコム</title>
              <meta
                key="description"
                name="description"
                content="外資系・日系超一流企業を目指す学生のための就職活動サイト。トップ企業の求人情報から、過去の選考情報・先輩のエントリーシ>ート・選考突破法・TOEIC勉強法・インターンシップまで多様なコンテンツを掲載。選考情報一括管理できるカレンダー機能も"
              />
              <meta
                key="keywords"
                name="keywords"
                content="外資,就活,外資系,就職,投資銀行,コンサル,商社"
              />
              <meta property="og:type" content="website" />
              <meta
                key="og:title"
                property="og:title"
                content="外資就活ドットコム"
              />
              <meta property="og:site_name" content="外資就活ドットコム" />
              <meta
                key="og:description"
                property="og:description"
                content="外資系・日系超一流企業を目指す学生のための就職活動サイト。トップ企業の求人情報から、過去の選考情報・先輩のエントリーシート・選考突破法・TOEIC勉強法・インターンシップまで多様なコンテンツを掲載。選考情報一括管理できるカレンダー機能も有"
              />
              <meta
                key="og:image"
                property="og:image"
                content={OGP_IMAGE_PATH_DEFAULT}
              />
              <meta
                key="og:url"
                property="og:url"
                content="https://gaishishukatsu.com/"
              />
              <meta property="og:locale" content="ja_JP" />

              <meta property="fb:app_id" content="152514804805611" />
              <meta
                name="facebook-domain-verification"
                content="rdf1tzoc35bm3zphygpoqnr9dkghrd"
              />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@gaishishukatsu" />

              <link
                rel="shortcut icon"
                type="image/x-icon"
                href={FAVICON_PATH}
              />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
            </Head>
            {error &&
            (error.name === ForbiddenError || error.name === BadRequestError) &&
            error.message ? (
              <ClientSideErrorPage message={error.message} />
            ) : (
              <>
                {getLayout(<Component {...pageProps} />)}
                <ScoutNotificationModal />
                <RegistrationNavigationFloating />
              </>
            )}
          </QueryClientProvider>
        </ClientProvider>
      </RecoilRoot>
      {/* Twitter conversion tracking base code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('config','oeh4c');
        `,
        }}
      />
      {/* End Twitter conversion tracking base code */}
      {/* LINE Tag Base Code  */}

      {/* !-- Do Not Modify */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(g,d,o){
            g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
            var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
            var s=d.createElement('script');s.async=1;
            s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
            var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
              })(window, document);
          _lt('init', {
            customerType: 'lap',
            tagId: '1010769f-1205-4450-a554-9f3e269d814c'
          });
          _lt('send', 'pv', ['1010769f-1205-4450-a554-9f3e269d814c']);
        `,
        }}
      />
      <noscript>
        <Image
          height="1"
          width="1"
          style={{display: 'none'}}
          src="https://tr.line.me/tag.gif?c_t=lap&t_id=1010769f-1205-4450-a554-9f3e269d814c&e=pv&noscript=1"
          alt="LINE Tag Base Code"
        />
      </noscript>
      {/* End LINE Tag Base Code */}

      {/* Meta Pixel Code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s){
          if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '533578025280022');
          fbq('track', 'PageView');
        `,
        }}
      />
      <noscript>
        <Image
          height="1"
          width="1"
          style={{display: 'none'}}
          src="https://www.facebook.com/tr?id=533578025280022&ev=PageView&noscript=1"
          alt="Meta Pixel Code"
        />
      </noscript>
      {/* End Meta Pixel Code */}

      {/** Microsoft Clarity */}
      {process.env.NEXT_PUBLIC_RELEASE_STAGE === 'production' && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: ` (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "nr7yywna6p");`,
          }}
        ></script>
      )}
    </>
  );
};

export default App;
